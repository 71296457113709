/* Table.css */

  
  .table thead th{
    color: black;
    font-weight: bold;
    font-size: 14px;
  }

  .table tbody td{
    color: black;
    font-size: 14px;
    margin-left: 20px;
  }

  .tbody tr th{
    margin-left: 10px;
  }